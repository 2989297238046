import React from "react"
import Routes from "./router/Routes"


const App = () => {

  return (
    <div className="tokyo_tm_all_wrap"> 
      <Routes/>
      
    </div>
   
  );
}

export default App;

