import React, { useState } from "react";
import Modal from "react-modal";
import Brand from "./Brand";

Modal.setAppElement("#root");

const About = () => {
  const [isOpen, setIsOpen] = useState(false);

  function toggleModal() {
    setIsOpen(!isOpen);
  }

  return (
    <>
      <div className="tokyo_tm_about">
        <div className="about_image">
          <img src="../assets/img/slider/about.jpg" alt="about" />
        </div>
        <div className="description">
          <h3 className="name">Rafael Cotrina Quesquen - Chi Sono</h3>
          <div className="description_inner">
            <div className="left">
              <p>
              Sono appassionato nel mio settore e provo molta soddisfazione 
              per la capacità di organizzare, svolgere e pianificare ogni 
              progetto lavorativo. Sono una persona motivata a crescere 
              professionalmente e orientata agli obiettivi principali.
              Cerco sempre di raggiungere il traguardo che mi sono 
              prefissato nei tempi stabiliti, per poi concentrarmi su quelli successivi.
              </p>
              <div className="tokyo_tm_button">
                <button onClick={toggleModal} className="ib-button">
                  Leggi di Più
                </button>
              </div>
            </div>  
            <div className="right">
              <ul>
                <li>
                  <p>
                    <span> Birthday:</span>23-02-1993
                  </p>
                </li>
                <li>
                  <p>
                    <span> Età:</span>28
                  </p>
                </li>
                <li>
                  <p>
                    <span>Email:</span>
                    <a target="noreferrer" href="mailto:i@rafaelcotrina.com">i@rafaelcotrina.com</a>
                  </p>
                </li>
                <li>
                  <p>
                    <span>Telefono:</span>
                    <a href="tel:+393290811391">+39 329 081 13 91</a>
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        contentLabel="My dialog"
        className="mymodal"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
      >
        <div className="tokyo_tm_modalbox_about">
          <button className="close-modal" onClick={toggleModal}>
            <img src="assets/img/svg/cancel.svg" alt="close icon" />
          </button>
          <div className="box-inner">
            <div className="description_wrap scrollable">
              <div className="about_title">
                <h3>My Skills</h3>
              </div>
              <div className="my_box">
                <div className="left">
                  <div className="tokyo_progress">
                    <div className="progress_inner">
                      <span>
                        <span className="label">* HTML</span>
                      </span>
                    </div>
                    <div className="progress_inner">
                      <span>
                        <span className="label">* CSS</span>
                      </span>
                    </div>
                    <div className="progress_inner">
                      <span>
                        <span className="label">* JAVA SCRIPT</span>
                      </span>
                    </div>
                    <div className="progress_inner">
                      <span>
                        <span className="label">* JQUERY</span>
                      </span>
                    </div>
                    <div className="progress_inner">
                      <span>
                        <span className="label">* TYPESCODE</span>
                      </span>
                    </div>
                    <div className="progress_inner">
                      <span>
                        <span className="label">* REACT</span>
                      </span>
                    </div>
                    <div className="progress_inner">
                      <span>
                        <span className="label">* ANGULAR</span>
                      </span>
                    </div>
                    <div className="progress_inner">
                      <span>
                        <span className="label">* PHP</span>
                      </span>
                    </div>
                    <div className="progress_inner">
                      <span>
                        <span className="label">* BOOTSTRAP</span>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="right">
                  <div className="tokyo_progress">
                    <div className="progress_inner">
                      <span className="label">* C++</span>
                    </div>
                    <div className="progress_inner">
                      <span className="label">* MY SQL</span>
                    </div>
                    <div className="progress_inner">
                      <span className="label">* MONGO DB</span>
                    </div>
                    <div className="progress_inner">
                      <span className="label">* PYTHON</span>
                    </div>
                    <div className="progress_inner">
                      <span className="label">* NODEJS</span>
                    </div>
                    <div className="progress_inner">
                      <span className="label">* GIT</span>
                    </div>
                    <div className="progress_inner">
                      <span className="label">* AWS</span>
                    </div>
                    <div className="progress_inner">                     
                      <span className="label">* LINUX</span>                      
                    </div>
                    <div className="progress_inner">
                      <span className="label">* APPS ADOBE</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="partners">
                <div className="about_title">
                  <h3>My Projects</h3>
                </div>
                <Brand />
              </div>
            </div>
          </div>
        </div>
      </Modal>
      
    </>
  );
};

export default About;
