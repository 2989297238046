import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function SimpleSlider() {
  var settings = {
    dots: false,
    arrow: false,
    infinite: true,
    speed: 5000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  return (
    <ul>
      <Slider {...settings}>
        <a href="https://latincreativity.com/" target="noreferrer">
          <li className="item">
            <img src="assets/img/partners/1.png" alt="latin creativity" />
          </li>
        </a>
        <a href="https://dxnami.com/" target="noreferrer">
          <li className="item">
            <img src="assets/img/partners/2.png" alt="dxnami" />
          </li>
        </a>
        <a href="https://esiscon.com/" target="noreferrer">
          <li className="item">
            <img src="assets/img/partners/3.png" alt="esiscon" />
          </li>
        </a>
        <a href="http://www.consulado.pe/es/roma/Paginas/Inicio.aspx/"
          target="noreferrer"
        >
          <li className="item">
            <img src="assets/img/partners/4.png" alt="consulado peru" />
          </li>
        </a>
        <li className="item">
          <img src="assets/img/partners/5.png" alt="radio latin style" />
        </li>
        <a href="https://raflix-4e3ab.web.app/" target="noreferrer">
          <li className="item">
            <img src="assets/img/partners/6.png" alt="raflix-clone" />
          </li>
        </a>
      </Slider>
    </ul>
  );
}
